.chat-drawer-container .channels-list-container {
  .chat-channel-divider {
    padding: 1.5rem 0.5rem 0.5rem 1rem;
    font-size: var(--font-0);
  }

  &.has-scrollbar {
    .chat-channel-row {
      margin-right: 0;
    }
  }

  .chat-channel-row {
    height: 3.6em;
    padding: 0 0.5rem;
    margin: 0 0.5rem 0 0.5rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--primary-low);
    }

    .chat-channel-metadata {
      .chat-channel-unread-indicator {
        margin-top: 0.25rem;
      }
    }

    &.--threads {
      margin-top: 0.25rem;

      .c-unread-indicator {
        width: 8px;
        height: 8px;
      }

      .chat-channel-title {
        gap: 0.5rem;
        color: var(--primary);
      }
    }
  }

  .toggle-channel-membership-button.-leave {
    margin-left: 0.5em;
  }
}

.chat-drawer-container .c-channel-settings,
.chat-drawer-container .c-channel-info__nav,
.chat-drawer-container .c-channel-members,
.chat-drawer-container .chat-message-creator-container {
  padding: 1rem;
  width: auto;
}

.chat-drawer-container .c-channel-info__nav,
.chat-drawer-container .c-channel-info__nav .nav-pills {
  padding-bottom: 0;
}

.chat-drawer-container .c-footer .chat-channel-unread-indicator.-urgent {
  width: min-content;
}
