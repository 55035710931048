#main-chat-outlet.chat-view {
  min-height: 0;
  display: grid;
  grid-template-areas: "main threads";
  grid-template-columns: 1fr auto;
}

.chat-side-panel {
  box-sizing: border-box;
  border-left: 1px solid var(--primary-low);
  position: relative;
  min-width: 250px;

  &__list {
    flex-grow: 1;
    padding: 0 1.5em 1em;
  }
}

#main-chat-outlet .chat-side-panel .c-navbar__title {
  margin-left: 0;
}
