.has-full-page-chat:not(.discourse-sidebar) {
  .full-page-chat {
    .channels-list {
      height: calc(100vh - var(--main-outlet-offset));
      border-right: 1px solid var(--primary-low);
      background: var(--secondary);
      overflow-y: auto;

      .channel-list-empty-message {
        padding: 1rem;
        text-align: center;

        svg,
        .channel-title {
          display: none;
        }
      }

      .chat-channel-divider {
        padding: 2rem 1rem 0.5rem 1rem;

        &:first-of-type {
          padding-top: 1.5rem;
        }
      }

      .loading-container {
        padding-bottom: 1em;
      }

      .chat-channel-row {
        height: 2.5em;
        padding: 0 0.5rem;
        margin: 0 0.5rem 0.125rem 0.5rem;

        &:hover,
        &.active {
          background-color: var(--primary-low);

          .chat-channel-title {
            .category-chat-name,
            .chat-name,
            .dm-usernames {
              color: var(--primary);
            }
          }
        }

        .chat-channel-metadata {
          &__date {
            display: none;
          }
        }
      }
    }

    .channels-list-container {
      height: auto;
    }

    .channels-list-container.center-empty-channels-list {
      height: 90%;
    }
  }
}
