@include breakpoint(large) {
  .main-chat-outlet.has-side-panel-expanded {
    .c-routes.--channel {
      display: none;
    }

    .chat-side-panel {
      width: 100% !important; // overrides the inline auto width
      border: none;

      .chat-side-panel-resizer {
        display: none;
      }
    }
  }
}
